import { format } from 'date-fns';

function ConnectionsTable({ data, is_pending }) {
  if (is_pending) {
    return <p></p>;
  }

  // If no data is passed or data is empty, display a message
  if (!data || data.length === 0) {
    return <p>No connections found.</p>;
  }

  return (
    <div className="w-full border-l border-green-400 border-l-4">
      <table className="w-full table-auto border-t-1">
        <thead>
          <tr className="text-left">
            <th className="border border-black px-4 py-4">Nummer</th>
            <th className="border border-black px-4 py-4">Von</th>
            <th className="border border-black px-4 py-4">Nach</th>
            <th className="border border-black px-4 py-4">Abfahrt</th>
            <th className="border border-black px-4 py-4">Ankunft</th>
            <th className="border border-black px-4 py-4">Dauer</th>
          </tr>
        </thead>

        {!is_pending && data.map((conn, _) => (
          <tbody className="">
            <tr key={conn.slugslug}>
              <td className="border border-black px-6 py-4">{conn.identifier}{conn.is_sprinter ? 's' : ''}</td>
              <td className="border border-black px-4 py-4">{conn.station_from_name}</td>
              <td className="border border-black px-4 py-4">{conn.station_to_name}</td>
              <td className="border border-black px-6 py-4">{format(new Date(conn.leaves),  'HH:mm')} Uhr</td>
              <td className="border border-black px-6 py-4">{format(new Date(conn.arrives), 'HH:mm')} Uhr</td>
              <td className="border border-black px-6 py-4">{conn.duration.replace(':00', '')}h</td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}

export default ConnectionsTable;
